import { BACKEND_DOMAIN, BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-user-api.hypexexch.com/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://dev-user-api.hypexexch.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://dev-user-reporting.hypexexch.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://dev-user-reporting.hypexexch.com/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://dev-agpay.hypexexch.com`,
};

export const StageUrlsMap = {
  REACT_APP_REST_API_URL: `https://dev-feature-api.hypexexch.com/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://dev-feature-api.hypexexch.com`,
  REACT_APP_REST_REPORTING_API_URL: `https://dev-feature-reporting.hypexexch.com`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://dev-feature-reporting.hypexexch.com/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://stage-user-api.hypexexch.com/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://v2-agpay.hypexexch.com`,
};

export const ProdUrls = {
  REACT_APP_REST_API_URL: `https://api.${BACKEND_DOMAIN}/api/v1`,
  REACT_APP_REST_API_V2_URL: `https://api.${BACKEND_DOMAIN}`,
  REACT_APP_REST_REPORTING_API_URL: `https://reporting.${BACKEND_DOMAIN}`,
  REACT_APP_WEBSOCKET_URL_PUSH_NOTIFICATIONS: `https://reporting.${BACKEND_DOMAIN}/push-notifications`,
  BINARY_WEBSOCKET_URL: `https://feed.mysportsfeed.io/hypex-ws`,
  REACT_APP_REST_AGPAY_URL: `https://agpay.${BACKEND_DOMAIN}`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrls,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
