import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import './LoginCard.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
// import ReCAPTCHA from 'react-google-recaptcha';
// Password Encryptioon
import JSEncrypt from 'jsencrypt';
import { RsaPublicKey } from '../../constants/RSAPublicKey';
import API_V2 from '../../api-services/svls-api';
import { DomainConfig } from '../../models/DomainConfig';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  redirectUrl?: string;
  domainConfig: DomainConfig;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    redirectUrl,
    domainConfig,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);

  let history = useHistory();

  // const forgotPwdHandler = () => {
  //   setShowForgotPwdModal(true);
  // };
  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess({ jwtToken: authToken });
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),

    onSubmit: async (values) => {
      if (values.code) {
        requestStart();
        const validateResponse = await API.post('/mfa/validate/key', {
          username: values.username.toLowerCase(),
          uuid: '',
          code: values.code,
        });
        if (validateResponse.data.success) {
          sessionStorage.setItem('username', values.username.toLowerCase());
          sessionStorage.setItem('jwt_token', loginResponse.jwtToken);
          setLoginResponse({ jwtToken: loginResponse.jwtToken });
          loginSuccess(loginResponse);
          let claim = loginResponse.jwtToken.split('.')[1];
          let permission = JSON.parse(window.atob(claim)).perm;
          let status = JSON.parse(window.atob(claim)).sts;
          if (status === 2) {
            history.replace('/terms-and-conditions');
          } else if (status === 4) {
            history.replace('/reset-password');
          } else if ((permission & 2) !== 0) {
            history.replace('/platform_admin/house');
          }
          if (redirectUrl) {
            console.log(redirectUrl);
            history.push(redirectUrl);
          }
        } else {
          loginFailed('Incorrect code');
        }
      } else {
        requestStart();
        try {
          const loginRequest = {
            username: values.username.toLowerCase(),
            password: values.password.trim(),
          };

          const response = await API_V2.post(
            '/account/v2/login',
            loginRequest,
            {
              responseType: 'text',
            }
          );
          requestEnd();

          sessionStorage.setItem('username', values.username.toLowerCase());
          sessionStorage.setItem('jwt_token', response.data);
          localStorage.removeItem(
            `multiMarket_${values?.username?.toLowerCase()}`
          );
          setLoginResponse(response.data);
          loginSuccess(response.data);

          let claim = response.data.split('.')[1];
          let permission = JSON.parse(window.atob(claim)).perm;
          let status = JSON.parse(window.atob(claim)).sts;
          sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);
          if (status === 2) {
            history.replace('/terms-and-conditions');
          } else if (status === 4) {
            history.replace('/reset-password');
          } else if ((permission & 2) !== 0) {
            history.replace('/platform_admin/house');
          }

          if (redirectUrl) {
            history.push(redirectUrl);
          } else {
            history.push('/home');
          }
        } catch (err) {
          loginFailed(err?.response?.data?.message);
        }
      }
    },
  });

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await API_V2.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        setLoginResponse(response.data);
        requestEnd();

        let claim = response.data.split('.')[1];
        const username = JSON.parse(window.atob(claim)).sub;
        sessionStorage.setItem('username', username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(`multiMarket_${username.toLowerCase()}`);

        loginSuccess(response.data);

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  const uniqueGuid = (): string => {
    const id = () => {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      id() +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      '-' +
      id() +
      id() +
      id()
    );
  };

  useEffect(() => {
    if (loggedIn) {
      history.push('/home');
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="login-form-ctn"
      autoComplete="off"
    >
      <div className="form-title web-view">
        LOGIN <i className="fas fa-hand-point-down"></i>
      </div>
      <div>
        {formik.touched.username && formik.errors.username ? (
          <div className="alert-danger">{formik.errors.username}</div>
        ) : null}

        {formik.touched.password && formik.errors.password ? (
          <div className="alert-danger">{formik.errors.password}</div>
        ) : null}
        {errorMsg !== '' ? (
          <div className="alert-danger">{errorMsg}</div>
        ) : null}
      </div>

      <div className="input-control usr-input">
        <TextField
          className="login-input-field"
          placeholder="User Name"
          type="text"
          name="username"
          variant="outlined"
          error={
            formik.touched.username && formik.errors.username ? true : false
          }
          // helperText={
          //     formik.touched.username && formik.errors.username
          //         ? formik.errors.username
          //         : null
          // }
          {...formik.getFieldProps('username')}
        />
      </div>

      <div className="pwd-input input-control">
        <FormControl
          className="pwd-field"
          variant="outlined"
          error={
            formik.touched.password && formik.errors.password ? true : false
          }
        >
          <OutlinedInput
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            {...formik.getFieldProps('password')}
            // endAdornment={
            //   <InputAdornment position="end" className="input-icon">
            //     <IconButton
            //       aria-label="toggle password visibility"
            //       onClick={showPasswordClickHandler}
            //       onMouseDown={showPasswordClickHandler}
            //     >
            //       {showPassword ? <Visibility /> : <VisibilityOff />}
            //     </IconButton>
            //   </InputAdornment>
            // }
          />
        </FormControl>
      </div>

      {useAuthenticator ? (
        <span className="code-input">
          <IonLabel className="input-label">Code</IonLabel>
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            variant="outlined"
            {...formik.getFieldProps('code')}
          />
        </span>
      ) : null}
      <div className="login-and-demologin">
        <Button
          className={`login-form-btn ${
            domainConfig.demoUser ? null : 'login-without-demoLogin'
          }`}
          endIcon={
            loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
          }
          type="submit"
        >
          <span className="ml-5">Login</span>
          <i className="fas fa-sign-in-alt" />
        </Button>
        {domainConfig.demoUser && (
          <Button
            className="login-form-btn"
            endIcon={
              demoLoading ? <IonSpinner name="lines-small" /> : ''
            }
            onClick={handleDemoLogin}
            >
            <span className="ml-5">Demo Login</span>
            <i className="fas fa-sign-in-alt" />
          </Button>
        )}
      </div>

      {/* <span className="recaptchaTerms">
        This site is protected by reCAPTCHA and the Google
        <a
          href="https://policies.google.com/privacy"
          className="text-primary nav-item"
        >
          {' '}
          Privacy Policy{' '}
        </a>
        and{' '}
        <a
          href="https://policies.google.com/terms"
          className="text-primary nav-item"
        >
          Terms of Service
        </a>{' '}
        apply.
      </span> */}
      {/* <Button className="login-form-btn">
        <span className="newlacunch-menu"> Download APK</span>
        <i className="fab fa-android" />{' '}
      </Button> */}

      <Modal
        open={showForgotPwdModal}
        closeHandler={() => setShowForgotPwdModal(false)}
        title="Forgot Password"
        size="xs"
      >
        <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
      </Modal>
    </form>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
    domainConfig: state.common.domainConfig,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
